<template>
  <v-container fluid>
    <TableViewComponent
      title="Table fonctions digitales"
      @addItemEvent="onAddFunction()"
      addItemLabel="ajouter une fonction digitale"
      itemLabel="fonction digitale"
      itemsLabel="fonctions digitales"
      :items="functions"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette fonction digitale ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { FonctionService } from "@/service/sfr/function_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableFunctions",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**le service d'accès au APIs. */
      serviceFunction: null,
      serviceServices: null,

      /**les fonctions */
      functions: [],
    };
  },
  methods: {
    onAddFunction() {
      this.$router.push(routes.functions.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.functions = [];

        let services = await this.serviceServices.getAllServices();

        //on charge les fonctions
        let functions = await this.serviceFunction.getFunctions();

        //let definition = null;

        functions.forEach((func) => {
          //le service associé
          let service = services.find(
            (service) => service.id == func.serviceId
          );

          //est-ce une fonction support?
          let isSupport = func.isSupport || false;

          //définition de la fonction? on garde les premiers mots pour l'affichage dans le tableau
          let definition = null;

          // la fonction a une définition
          if (func.definition) {
            // let words = func.definition.split(" ");
            // let length = words.length < 8 ? words.length : 8;
            // let firsts = words.slice(0, length);
            // definition = firsts.join(" ") + "...";
            definition = func.definition;
          }

          let f = {
            id: func.id,
            function: func,
            service,
            name: func.name,
            serviceName: service.name,
            isSupport: isSupport ? "support" : "non support",
            definition,
            idDrive: func.idDrive,
          };

          //action de consultation
          f.view = () => {
            this.$router.push(routes.functions.view.root + "/" + func.id);
          };

          //action de modification
          f.edit = () => {
            this.$router.push(routes.functions.edit.root + "/" + func.id);
          };

          //action de suppression
          f.delete = async () => {
            try {
              await this.serviceFunction.deleteFunction(func.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la fonction digitale : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.functions.push(f);
        });

        // console.log(JSON.stringify(this.functions, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "name",
          default: true,
        },
        {
          text: "Service",
          align: "start",
          sortable: true,
          value: "serviceName",
        },
        {
          text: "Définition",
          align: "start",
          sortable: true,
          value: "definition",
          default: false,
        },
        {
          text: "Support",
          align: "start",
          sortable: true,
          value: "isSupport",
          default: true,
        },
        {
          text: "id drive",
          align: "start",
          sortable: false,
          value: "idDrive",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditServiceSFR];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditServiceSFR, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadServiceSFR];
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceServices = new ServicesService(this.$api);

    this.load();
  },


};
</script>
